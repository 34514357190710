import React from "react";


export default function TextLogo() {
  return (
    <div className='site-title-wrapper'>
      <h1 className='site-title'>
        <div>
          <span className="site-first-word">afford</span>
          <span className="site-period">.</span>
        </div>
        <div>
          <span className="site-second-word">nyc</span>
        </div>
      </h1>
    </div>
  );
}