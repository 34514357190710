import React from 'react';
import './index.css';


export default function Hamburger({ onClick, asX }) {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div className={`hamburger-icon${asX ? ' hamburger-icon-as-x' : ''}`} onClick={onClick}>
        <div className="hamburger-line hamburger-line-top" />
        <div className="hamburger-line hamburger-line-middle" />
        <div className="hamburger-line hamburger-line-bottom" />
      </div>
    )
}