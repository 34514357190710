import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import config from '../../data/SiteConfig';
import TextLogo from '../pageComponent/TextLogo';
import { checkIsMobile, checkIsChrome } from '../utils/userAgentParsing';
import { extensionIsInstalled, listenForExtensionInstalled } from '../utils/extensionInstalledCheck';
import './index.css';
import Hamburger from '../pageComponent/Hamburger';
import chromeExtensionUrl from '../utils/chromeExtensionUrl';


const exactConditionalClass = ({ isCurrent }) =>
  isCurrent ? { className: "nav-link current" } : null;

const exactConditionalClassMobile = ({ isCurrent }) =>
  isCurrent ? { className: "nav-link-mobile current" } : null;

const conditionalClass = ({ isPartiallyCurrent }) =>
  isPartiallyCurrent ? { className: "nav-link current" } : null;

const conditionalClassMobile = ({ isPartiallyCurrent }) =>
  isPartiallyCurrent ? { className: "nav-link-mobile current" } : null;

export default class MainLayout extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      hasExtension: extensionIsInstalled(),
      mobileNavOpen: false,
      isMobile: false,
      isChrome: true
    };

    this.toggleMobileNavOpen = () => this.setState({ mobileNavOpen: !this.state.mobileNavOpen });
  }

  componentDidMount() {
    const hasExtension = extensionIsInstalled();
    const isMobile = checkIsMobile();
    const isChrome = checkIsChrome();
    if (this.state.isMobile !== isMobile || this.state.isChrome !== isChrome || this.state.hasExtension !== hasExtension) {
      setTimeout(() => {
        this.setState({
          hasExtension,
          isMobile,
          isChrome
        });
      });
    }
    if (!this.state.hasExtension) {
      this.unlisten = listenForExtensionInstalled(() => this.setState({ hasExtension: true }));
    }
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  render() {
    const { children } = this.props;
    const { hasExtension, mobileNavOpen, isMobile, isChrome } = this.state;
    return (
      <div className="root __x__">
        <Helmet>
          <meta name="description" content={config.siteDescription} />
          <link href="https://fonts.googleapis.com/css?family=Montserrat:800|Noto+Sans:400,700&display=swap" rel="stylesheet" />
        </Helmet>
        <div className="nav-root main-text">
          <div className="nav-wrapper">
            <div className="left-side">
              <Link
                className='header-home-link'
                to='/'
              >
                {/* <img
                  className='site-logo'
                  alt='Afford NYC Logo'
                  src={logo}
                /> */}
                <TextLogo />
              </Link>
            </div>

            <div className="right-side">
              <div className="nav-links-desktop">
                <Link
                  className='nav-link'
                  to='/'
                  getProps={exactConditionalClass}
                >
                  Home
                </Link>
                <Link
                  className='nav-link'
                  to='/about'
                  getProps={conditionalClass}
                >
                  About Us
                </Link>
                {/* blog not ready yet */}
                {/* <Link
                  className='nav-link'
                  to='/blog'
                  getProps={conditionalClass}
                >
                  Blog
                </Link> */}
                <Link
                  className='nav-link'
                  to='/how-it-works'
                  getProps={conditionalClass}
                >
                  How It Works
                </Link>
                {
                  hasExtension ? (
                    <Link
                      className='nav-link'
                      to='/saved-homes'
                      getProps={conditionalClass}
                    >
                      Saved Homes
                    </Link>
                  ) : (
                    <a
                      href={chromeExtensionUrl}
                      className={`install-button-nav ${!isChrome ? 'install-button-nav-unavailable' : ''}`}
                    >
                      {!isChrome ? "Available for Chrome" : "Install Now"}
                    </a>
                  )
                }
              </div>
              <div className="nav-mobile-hamburger">
                <Hamburger onClick={this.toggleMobileNavOpen} asX={mobileNavOpen} />
              </div>
            </div>
          </div>
          <div className={`nav-links-mobile ${mobileNavOpen ? ' nav-links-mobile-open' : ''}`}>
            <Link
              className='nav-link-mobile'
              to='/'
              getProps={exactConditionalClassMobile}
            >
              Home
            </Link>
            <Link
              className='nav-link-mobile'
              to='/about'
              getProps={conditionalClassMobile}
            >
              About Us
            </Link>
            {/* blog not ready yet */}
            {/* <Link
              className='nav-link-mobile'
              to='/blog'
              getProps={conditionalClassMobile}
            >
              Blog
            </Link> */}
            <Link
              className='nav-link-mobile'
              to='/how-it-works'
              getProps={conditionalClassMobile}
            >
              How It Works
            </Link>
            {
              hasExtension ? (
                <Link
                  className='nav-link-mobile'
                  to='/saved-homes'
                  getProps={conditionalClassMobile}
                >
                  Saved Homes
                </Link>
              ) : (
                <div className="nav-link-mobile">
                  <a
                    href={chromeExtensionUrl}
                    className={`install-button-nav ${!isChrome ? 'install-button-nav-unavailable' : ''}`}
                  >
                    {
                      // eslint-disable-next-line no-nested-ternary
                      isMobile ? 'Available on Chrome Desktop' : !isChrome ? "Available for Chrome" : "Install Now"
                    }
                  </a>
                </div>
              )
            }
          </div>
          <div className={`nav-open-cover ${mobileNavOpen ? ' nav-open-cover-show' : ''}`} />
        </div>
        <div className="main-content">
          {children}
        </div>
      </div>
    );
  }
}
