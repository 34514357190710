
const extensionInstalledEventName = "____3712020236769449____";
const listenerConfig = { once: true };
const isListenerInstalledContainer = { [extensionInstalledEventName]: false };

export function extensionIsInstalled() {
    return isListenerInstalledContainer[extensionInstalledEventName];
}

export function listenForExtensionInstalled(listener) {
    // already set
    if (isListenerInstalledContainer[extensionInstalledEventName]) {
        listener();
        return () => {};
    }

    // set from window
    if (window[extensionInstalledEventName]) {
        isListenerInstalledContainer[extensionInstalledEventName] = true;
        listener();
        return () => {};
    }

    const wrappedListener = () => {
        isListenerInstalledContainer[extensionInstalledEventName] = true;
        listener()
    }

    window.addEventListener(extensionInstalledEventName, wrappedListener, listenerConfig);

    return function unlistenForExtensionInstalled() {
        return window.removeEventListener(extensionInstalledEventName, wrappedListener, listenerConfig);
    };
}
