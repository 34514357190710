const isMobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i;
const isChromeRegex = /Chrome/i;

export function checkIsMobile() {
    return isMobileRegex.test(window.navigator.userAgent);
}

export function checkIsChrome() {
    return isChromeRegex.test(window.navigator.userAgent);
}
